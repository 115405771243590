#subscription-progressbar {

  // desktop
  .desktop ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin: 2rem 0 2.5rem 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;

    li {
      list-style-type: none;
      color: $grey;
      font-size: 0.875rem;
      position: relative;
      flex: 1 0 0;

      i {
        color: $white;
        font-size: 1.688rem;
        position: absolute;
        top: 0.75rem;
        left: 0.25rem;
        width: 100%;
        text-align: center;
        z-index: 4;
      }

      span {
        color: $primary-color;
        font-size: 1.188rem;
        position: absolute;
        font-weight: 600;
        top: 0.813rem;
        left: 0.25rem;
        width: 100%;
        text-align: center;
        z-index: 4;
        line-height: 1.4;
      }

      h4 {
        font-size: 1.2rem;
        line-height: 1.4;
        text-align: center;
        margin: 0.75rem 0 0.25rem;
      }

      p {
        margin: 0;
        text-align: center;
        line-height: 1.6;
        font-size: 0.875rem;
      }

      &:before {
        content: '';
        width: 3.125rem;
        height: 3.125rem;
        display: block;
        background: $hover-main-menu-buttons;
        border-radius: 50%;
        position: relative;
        left: calc(50% - 22px);
        z-index: 4;
      }

      &:not(:first-child):after {
        content: '';
        left: -38%;
        width: 78%;
        height: 0.125rem;
        background: $lightgrey;
        position: absolute;
        top: 1.438rem;
        z-index: 2;
      }

      &:nth-child(2) {
        z-index: 3;
      }

      &.active:before,
      &.active:after {
        background: $primary-color;
      }

      &.active i,
      &.active span {
        color: $white;
      }
    }
  }
}

.progressbar__mobile {
  margin-bottom: 1.5rem;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
  }

  &__item {
    color: $grey;
    font-size: 0.875rem;
    position: relative;
  }

  &__icon {
    width: 3.125rem;
    height: 3.125rem;
    display: block;
    background: $primary-color;
    border-radius: 50%;
    position: relative;
  }

  &__step {
    color: $white;
    font-size: 1.188rem;
    position: absolute;
    font-weight: 600;
    top: 0.813rem;
    left: 0.063rem;
    width: 100%;
    text-align: center;
    z-index: 4;
    line-height: 1.4;
  }

  &__heading {
    font-size: 1.2rem;
    line-height: 1.4;
    margin: 0.125rem 0 0 0.75rem;
    color: $font-primary-color;
  }
}

@media (max-width: 768px) {

  #subscription-progressbar {

    .desktop {
      display: none;
    }

    .progressbar__mobile {
      display: block;
    }
  }
}

@media (min-width: 769px) {

  #subscription-progressbar {

    .desktop {
      display: block;
    }

    .progressbar__mobile {
      display: none;
    }
  }
}

.flex {
  display: flex;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.overflow-x-auto {
  overflow-x: auto;
}

.row--horizontal-center {
  justify-content: center;
}

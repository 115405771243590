@import './assets/huurstunt/scss/utilities/mixins/menu';

// Global styles
html.no-scroll {
  overflow: hidden;
  height: 100vh;
}

#wrapper.search-active::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: #000000;
  z-index: 996;
  transition: opacity 0.3s linear;
}

// Page specific
#route-to-rental-supply_address {
  nav.main-menu.transparent {
    .main-menu__mobile {
      background: transparent;
    }
  }
  nav.main-menu.transparent.scroll-position-below-transparency {
    .main-menu__mobile {
      background: #f9f9f9;
    }
  }
}

// Main menu styles
nav.main-menu {
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 15px; // Emulate same padding as bootstrap
  height: $menu-height-mobile;
  background: $white;
  border-bottom: 1px solid $border-light-grey;
  margin: 0;
  transition: top 0.4s;

  a:hover {
    color: inherit;
  }

  p {
    margin: 0;
    color: $primary-color;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .main-menu__website-of-the-year.delay-off {
    display: none;
  }

  .website-of-the-year {
    margin: 0 5px;
  }

  &.transparent:not(.transparent-only-desktop) {
    transition: background #{$menu-transition-time-in-seconds}s linear;
    @include transparent;
  }

  &.transparent-only-desktop {
    transition: background #{$menu-transition-time-in-seconds}s linear;
    @include showWhiteLogo(false);
  }

  .main-menu__logo-container {
    display: flex;
    flex: 0;

    a.main-menu__logo {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .main-menu__search__button {
    @include animate-close-icon('f002', $primary-color, 'main-menu__search__button--expand', $menu-transition-time-in-seconds);
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;

    &:hover {
      background-color: #f9f9f9;
    }
  }

  .main-menu__mobile {
    @include animate-close-icon('f0c9', $primary-color, 'main-menu__mobile--slide-in', $menu-transition-time-in-seconds);
    width: 40px;
    height: 40px;
    background-color: #f9f9f9;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus {
      outline: auto;
    }

    &:focus-visible {
      border-style: solid;
    }

    + .notification-wrapper {
      position: relative;
    }
  }

  .main-menu__mobile.main-menu__mobile--slide-in {
    background-color: #f9f9f9;
    border-radius: 2px;
  }

  ul.main-menu__options {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin: 0;

    .notification {
      right: -20px;
    }

    li {
      white-space: nowrap;

      a {
        &:hover {
          color: $font-primary-color;
        }

        i {
          margin-right: 0.25rem;
        }
      }
    }

    li.main-menu__options__account {
      cursor: pointer;
      padding-right: 1.5rem;
      display: flex;
      gap: 0.5rem;
      @include rotate-arrow($primary-color, $menu-transition-time-in-seconds, 0px, '.main-menu__mobile--slide-in');

      &:hover {
        color: $font-primary-color;
      }
    }

    .user-image img {
      margin-right: 7px;
      border-radius: 100%;
      border: 2px solid $white;
    }
  }
}

.main-menu__search {
  width: 100%;

  #main-menu__search__form {
    position: relative;

    button {
      background: $primary-color;
      color: $white;
      outline: none;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      border-radius: 0 0.25rem 0.25rem 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      i {
        text-align: center;
      }
    }

    .main-menu__search__input {
      height: 40px;
      padding: 0.5rem;
      font-size: 0.875rem;
      color: $font-primary-color;
      border-radius: 0.25rem;
      border: 1px solid $border-light-grey;
      position: relative;
      vertical-align: top;
      background-color: #f9f9f9;
      width: 100%;

      &::placeholder {
        color: $font-secondary-color;
        opacity: 1;
      }

      &:focus {
        outline: 1px solid $primary-color;
      }

      &:hover {
        border: 1px solid $border-hover;
      }
    }
  }
}

// Media queries
@include media-breakpoint-up(sm) {
  nav.main-menu {
    .stretch {
      flex: 1;
    }
  }
}

@include media-breakpoint-up(md) {
  nav.main-menu {
    height: $menu-height-desktop;
    padding: 0 3rem;

    .main-menu__logo-container {
      flex: 1;
    }

    &.transparent-only-desktop {
      @include transparent;
    }

    .main-menu__search__button {
      margin-right: 1rem;
    }

    .website-of-the-year {
      margin-left: 0;
    }

    .main-menu__website-of-the-year.delay-off {
      display: block;
    }
  }

  .main-menu__search {
    max-width: 30rem;

    #main-menu__search__form {

      .main-menu__search__input {
        height: 44px;
        width: 100%;
      }
    }
  }
}

// Collapsable menu
.scroll-position-below-collapsed {
  top: -#{$menu-height-mobile};

  + .slide-menu + #wrapper {
    margin-top: 0;

    #vue-searchpage .mobile-search.fixed .options .filter-container {
      height: calc(100vh - 110px + #{$menu-height-mobile});
    }
  }
}

// Typography
body {
  font-family: "Poppins", "Muli", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
  font-weight: 600;
}

h1 {
  line-height: 40px;
  font-size: 1.8em;
}

h2 {
  font-size: 1.6em;
  line-height: 1.2em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.8em;
}

p {
  line-height: 1.4;
}

p,
a,
li,
label,
body {
  color: #6c7587;
  font-size: 1em;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a,
legend,
.form-group > label,
.form-group > * > label {
  color: #2D3954;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.text-light {
    color: #f8f9fa;
  }
}

.block-header {
  padding-bottom: 0.3em;
}

.text-container {
  p + h1,
  p + h2,
  p + h3,
  ul + h1,
  ul + h2,
  ul + h3,
  ol + h1,
  ol + h2,
  ol + h3,
  hr + h1,
  hr + h2,
  hr + h3,
  h1 + h2,
  h1 + h3,
  h1 + h4,
  h2 + h3,
  h2 + h4 {
    margin-top: 2em;
  }

  p + h4,
  p + h5,
  p + h6,
  ul + h4,
  ul + h5,
  ul + h6,
  ol + h4,
  ol + h5,
  ol + h6,
  hr + h4,
  hr + h5,
  hr + h6 {
    margin-top: 1.8em;
  }
}

// page title
.sec-heading {
  margin-bottom: 2em;

  &.center {
    text-align: center;
  }

  // subtitle
  p {
    font-family: inherit;
    font-size: 1.1em;
    font-style: normal;
  }

  h2 + p {
    margin-top: 0.4rem;
  }

  // title decoration
  &.sec-heading--line-before {
    h2 {
      &::before {
        content: '';
        width: 10%;
        height: 1px;
        position: absolute;
        left: 50%;
        top: -2rem;
        transform: translate(-50%, -50%);
        background-color: #333;
      }
    }
  }
}

// RIKADA THEME
.block-header {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e7eff9;
}

.block-body {
  line-height: 1.7;
}

.ipt-title {
  margin-bottom: 5px;
}

@include media-breakpoint-up(lg) {
  .sec-heading h2 {
    font-size: 29px;
    line-height: 1.2;
  }
  .sec-heading p {
    font-size: 20px;
  }
}

@include media-breakpoint-down(xs) {
  .page-title h1.ipt-title,
  .page-title h2.ipt-title {
    margin-top: 10px;
  }
}

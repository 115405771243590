@import "~bootstrap/scss/variables";

// display
$fa-font-display: block;

// colors
$lightgrey: #eaf0f4;
$light-grey: #a2a9bf;
$icon-grey: #9f9f9f;
$border-hover: #afadad;
$light-navy-blue: #3071a7;
$dark-navy-blue: #2d4e6a;
$light-green: #0084890d;
$dark-green: #008489;
$grey: #99a2a8;
$primary-dark-blue: #2d3954;
$row-light-grey: #f1f3f8;
$border-light-grey: #eaecf1;
$primary-color: #2d4e6a;
$secondary-color: #c23b37;
$theme-color: #008489;
$light-background: #f7f9fc;
$hover-main-menu-buttons: #dae3ea;
$hover-secundary-menu-buttons: #d7dae1;
$white: #ffffff;
$black: #000000;
$pure-red: #ff0000ff;

// property
$property-active: #008489;
$property-under-option: #faa125;
$property-rented-out: #c23b37;

// fonts
$font-primary-color: #2d3954;
$font-secondary-color: #6c7587;
$font-primary-check: #007119;

// icons
$icon-secondary-color: #6c7587;

// other
$activeprogress: #008489;
$share-border-mobile: #eaecf1;
$share-shadow-mobile: #f1f1f1;
$outline: #1c1c3c;
$form-input-background: #f9f9f9;
$form-input-divider: #E1E5F2;
$form-social-border: #e4e6e6;
$form-links: #007BFF;

// theme colors
$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "theme": $theme-color
);

// menu
$menu-items-spacing: 10px;
$menu-height-mobile: 65px;
$menu-height-desktop: 70px;
$menu-slider-mobile-width: 100vw;
$menu-slider-desktop-width: 430px;
$menu-transition-time-in-seconds: 0.3;

// Socials
$facebook-color: #3b5998;

.boxed-widget {
  @include boxed-widget;
}

.boxed-widget--clean {
  @include boxed-widget--clean;
}

.boxed-widget--padding-xs {
  @include boxed-widget(1);
}

.boxed-widget--padding-sm {
  @include boxed-widget(1.5);
}

.boxed-widget--padding-md {
  @include boxed-widget;
}

.boxed-widget--padding-lg {
  @include boxed-widget(3.25);
}

.boxed-widget--padding-xl {
  @include boxed-widget--padding-xl;
}

.boxed-widget--mobile {
  @include boxed-widget--mobile;
}

.boxed-widget--stretch {
  @include boxed-widget--stretch;
}

[class^='boxed-widget'] {
  h1.ipt-title {
    margin-bottom: 1.3em;
  }
}

// Import bootstrap buttons here to overwrite design btn by template
@import "~bootstrap/scss/buttons";

.btn-no-border {
  border: 0;

  &:focus {
    outline: 0;
  }
}

.btn-custom-submit[type=submit] {
  background: #008489;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.8rem;
  font-size: 1.1rem;
  margin-bottom: 5px;

  i {
    color: white;
    margin-top: 2px;
    margin-left: 8px;
  }

  p {
    color: white;
    margin: 0;
  }
}

.btn {
  transition: all .3s;
  font-weight: 500;

  &.btn-theme p {
    color: $white;
  }

  // tertiary button
  &.btn-tertiary {
    background-color: #dae3ea;
    color: $primary-color;
    border-color: #dae3ea;
    &:hover,
    &:focus,
    &active {
      background-color: #beceda;
      color: $primary-color;
      border-color: #beceda;
    }
  }

  // default
  &.btn-default {
    background-color: #fff;
    color: #707070;
    border-color: #707070;

    &:hover,
    &:focus,
    &:active {
      background-color: #707070;
      border-color: #676767;

      .fas,
      .far {
        color: #fff;
      }
    }

    .fas,
    .far {
      color: $primary-color;
    }
  }

  // danger
  &.btn-danger {
    background: #dc3545;
    color: $white;
  }

  // warning
  .btn-warning {
    background: #ff9b20;
    border: 1px solid #ff9b20;
    color: #ffffff;
  }

  // success
  &.btn-success {
    background: $dark-green;
    border: 1px solid $dark-green;
    color: $white;
  }

  // search
  &.search-btn {
    box-shadow: none;
    background-color: $theme-color;
    border-color: $theme-color;
    font-weight: normal;
    padding: 17px;
    border-radius: 5px;
    color: #ffffff;
    width: 100%;
    font-size: 1.2rem;
  }

  // theme
  &.btn-theme {
    background-color: $theme-color;
    margin-right: 0;
    border: 1px solid $theme-color;
    border-radius: 4px;
    margin-bottom: 5px;

    &:hover,
    &:active,
    &:focus {
      background-color: $theme-color;
      border: 1px solid $theme-color;
      color: #ffffff;
    }
  }

  .btn-theme,
  .btn-theme-2 {
    color: #fff;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }

  // outline-primary
  &.btn-outline-primary {
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .fas,
    .far {
      color: $primary-color;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
      .fas,
      .far {
        color: #fff;
      }
    }
  }

  &.btn-success,
  &.btn-default,
  &.btn-danger,
  &.btn-primary,
  &.btn-secondary {
    &:focus {
      color: #fff;
    }
  }

  &.btn-success,
  &.btn-default,
  &.btn-danger,
  &.btn-primary,
  &.btn-secondary,
  &.btn-outline-secondary,
  &.btn-outline-primary,
  &.btn-outline-dark {
    &:hover,
    &:active {
      color: #fff;
    }
  }

  .fas {
    color: #fff;
  }
}

// Custom buttons:
.green-skin .btn-dashed {
  border-bottom: 1px dashed;
  color: #797d8a;

  &:hover,
  &:active,
  &:focus {
    color: #797d8a;
    text-decoration: none;
  }
}

.fileuploader-input-button {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

@import './assets/huurstunt/scss/utilities/mixins/menu';

// Side menu
ul.slide-menu {
  position: fixed;
  overflow-x: hidden;
  width: $menu-slider-mobile-width;
  right: -#{$menu-slider-mobile-width};
  top: 0;
  height: 100vh;
  padding: $menu-height-mobile 1rem 5.063rem 1rem; // Custom padding for mobile slide menu
  list-style: none;
  background: $white;
  transition: right #{$menu-transition-time-in-seconds}s linear;
  z-index: 998;
  display: flex;
  flex-direction: column;

  &.main-menu__mobile--slide-in {
    right: 0;
  }

  li:not(:last-child) {
    border-bottom: 1px solid $border-light-grey;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: $font-primary-color;
      border-bottom: 1px solid $font-primary-color;
    }

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      height: 100%;
      padding: 1rem 0;

      &:hover {
        text-decoration: none;
      }

      i {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        width: 24px;
      }

    }
    a.active {
      color: $font-primary-color;
      border-bottom: 1px solid $font-primary-color;
    }
  }

  .slide-menu__logout {
    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      height: 100%;
      padding: 1rem 0;

      &:hover {
        text-decoration: none;
      }

      i {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        width: 24px;
      }
    }
  }

  li {
    a.btn__primary-sidemenu {
      background-color: $dark-green;
      color: $white;
      font-weight: 600;
      margin: 0;
      min-width: 100%;
      padding: 1rem;
      margin: 1rem 0;
      box-shadow: 0 9px 21px 0 rgba(0, 132, 137, 0.1), 0 38px 38px 0 rgba(0, 132, 137, 0.09), 0 85px 51px 0 rgba(0, 132, 137, 0.05), 0 151px 60px 0 rgba(0, 132, 137, 0.01), 0 236px 66px 0 rgba(0, 132, 137, 0);
      &:hover {
        background-color: #007175;
      }
    }
  }

  .user-name {
    position: relative;

    .notification {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: -20px;
      right: -12px;
      margin: auto 0;
      background: #ff7d7d;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      color: #ffffff;
      font-size: 8px;
      font-weight: 600;
      border: 1px solid white;
      line-height: 7px;
      box-shadow: rgb(140, 32, 32) -5px -5px 10px 0px inset;
    }
  }

  .user-image {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 100%;
      border: 2px solid $white;
    }
  }

  .rent-house {
    border-bottom: 1px solid $border-light-grey;
    transition: all 0.15s ease-in-out;

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      height: 100%;
      padding: 1rem 0;

      i {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        width: 24px;
      }

      &:hover {
        color: $font-primary-color;
        text-decoration: none;
      }
    }

    &:hover {
      color: $font-primary-color;
      border-bottom: 1px solid $font-primary-color;
    }
  }
  .rent-house.active {
    a {
      color: $font-primary-color;
      border-bottom: 1px solid $font-primary-color;
    }
  }
}

// Wrapper styles
#wrapper {
  position: relative;
  margin-right: 0;
  margin-top: $menu-height-mobile;
  width: 100%;
  left: 0;
  transition: margin-top 0.4s, left #{$menu-transition-time-in-seconds}s linear;

  &:not(.transparent) {
    background: $light-background;
  }

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    background: $black;
    transition: opacity #{$menu-transition-time-in-seconds}s linear;
    z-index: 997;
  }

  &.main-menu__mobile--slide-in {
    left: -#{$menu-slider-mobile-width};

    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0.4;
    }
  }

  &.wrapper-trustpilot {
    margin-top: 108px;
  }
}

// Desktop-specific styles
@include media-breakpoint-up(md) {
  #wrapper {
    margin-top: $menu-height-desktop;

    &.main-menu__mobile--slide-in {
      left: -#{$menu-slider-desktop-width};
    }

    &.wrapper-trustpilot {
      margin-top: 113px;
    }
  }

  ul.slide-menu {
    width: $menu-slider-desktop-width;
    right: -#{$menu-slider-desktop-width};
    padding: 5.375rem 3rem;
    gap: 0.5rem;

    li > a,
    li > div {
      transition: all #{$menu-transition-time-in-seconds / 2}s linear;
    }
  }
}

@import './assets/huurstunt/scss/utilities/common';

.main-menu-top-trustpilot {
  top: 43px;
}

.main-menu-top-no-trustpilot {
  top: 0;
}

.trustpilot-home-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.trustpilot-home {

  &__logo {
    height: 1.25rem;
    width: auto;
    vertical-align: sub;
    margin-top: -5rem;
  }

  .trustpilot-home__rating {

    &__description {
      display: inline-block;
      color: $white;
      font-size: 0.875rem;
      font-weight: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
    }
  }

  &__link {
    color: $white;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-decoration: underline;
  }

  &__link:hover {
    color: inherit;
    text-decoration: none;
  }
}

.trustpilot-header {
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 43px;
  top: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.688rem;
  background-color: $primary-color;
  transition: top 0.4s;
  color: $white;
  .__fbcw__bar-widget__amount-of-reviews {
    color: $white !important;
  }
  &__link {
    display: block;
  }
}

.trustpilot-widget {
  width: 100%;
}

.trustpilot-widget.home-widget {
  padding-bottom: 2rem;
}

.trustpilot-home__rating,
.trustpilot-header__rating {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: url('../../../../../../../public/cdn/images/trustpilot/trustpilot-ratings-green.png');
    background-repeat: no-repeat;
    top: -0.5rem;
    left: 0.3125rem;
  }

  &.star10:after,
  &.star1:after,
  &.star15:after,
  &.star20:after,
  &.star2:after,
  &.star25:after,
  &.star30:after,
  &.star3:after,
  &.star35:after,
  &.star40:after,
  &.star4:after,
  &.star45:after,
  &.star50:after,
  &.star5:after {
    background-size: 21.75rem 17.3125rem;
    width: 6.9375rem;
    height: 1.5625rem;
  }

  &.star10:after,
  &.star1:after {
    background-position: 51.698% 88.5%;
  }

  &.star15:after {
    background-position: 51.698% 78.5%;
  }

  &.star20:after,
  &.star2:after {
    background-position: 51.698% 68.5%;
  }

  &.star25:after {
    background-position: 51.698% 58.6%;
  }

  &.star30:after,
  &.star3:after {
    background-position: 51.607% 49%;
  }

  &.star35:after {
    background-position: 51.607% 38.8%;
  }

  &.star40:after,
  &.star4:after {
    background-position: 51.607% 29%;
  }

  &.star45:after {
    background-position: 51.698% 19%;
  }

  &.star50:after,
  &.star5:after {
    background-position: 51.698% 8.5%;
  }
}

.trustpilot-home__rating {

  &:after {
    background: url('../../../../../../../public/cdn/images/trustpilot/trustpilot-ratings-white.png');
    top: -0.25rem;
    left: 0;
  }
}

.trustpilot-home__rating__description {
  margin-bottom: 0;

  &--star-margin {
    margin-left: 6.813rem;
  }
}

@include media-breakpoint-down(sm) {

  .trustpilot-widget.home-widget {
    padding-bottom: 0;
  }

  .trustpilot-home-footer {
    margin-top: 0;
    text-align: center;

    .trustpilot-home {

      &__logo {
        margin-top: -0.3125rem;
      }

      .trustpilot-home__rating__description {
        display: block;
        line-height: 1;
        color: $font-secondary-color;

        &--star-margin {
          margin-left: 0;
        }
      }

      &__link {
        color: $font-secondary-color;
      }
    }
  }
}

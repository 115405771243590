// notification
.main-menu,
.slide-menu {
  .notification {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    right: -10px;
    margin: auto 0;
    background: #ff7d7d;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    color: #ffffff;
    font-size: 9px;
    line-height: 8px;
    font-weight: 600;
    border: 2px solid #fff;
    box-shadow: #8c2020 -5px -5px 10px 0px inset;
    pointer-events: none;
  }

  // notification after
  .notification-after {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -5px;
      right: -20px;
      margin: auto 0;
      background: #ff7d7d;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      color: #ffffff;
      font-size: 9px;
      line-height: 8px;
      font-weight: 600;
      border: 2px solid #fff;
      box-shadow: #8c2020 -5px -5px 10px 0px inset;
      pointer-events: none;
    }
  }
}

// Removes default styling of html tags

// list
.clean-text-container ul,
ul.clean {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// button
.clean-text-container button,
.clean-text-container .btn,
button.clean,
.btn.clean {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }
}

// links
.clean-text-container a,
a.clean {
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.no-border {
  border: 0 !important;
}

p.clean {
  margin: 0;
}

.hero-banner {
  position: relative;
  padding: 5em 0 5em;
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}

.hero-search-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  .hero-search-wrap {
    position: relative;
    background: $white;
    width: fit-content;
    text-align: center;
    border-radius: .5rem;
    border: solid 1px $border-light-grey;
    display: flex;
    flex-direction: column;

    .hero-search-toggle {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $border-light-grey;
      list-style: none;
      background-color: #f9f9f9;
      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          border-right: 1px solid $border-light-grey;
        }

        &.list-active {
          background-color: $white;
        }

        button {
          -webkit-appearance: none;
          border-radius: 0;
          text-align: inherit;
          background: none;
          box-shadow: none;
          padding: 1rem;
          width: 100%;
          cursor: pointer;
          border: none;
          color: inherit;
          font: inherit;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: .25rem;

          svg {
            fill: $font-secondary-color;
          }

          &.button-active {
            color: $dark-green;
            svg {
              fill: $dark-green;
            }
          }

          &:hover {
            color: $dark-green;
            svg {
              fill: $dark-green;
            }
          }
        }
      }
    }

    .hero-search {
      padding: 2rem 2rem 0 2rem;
      h1, h2 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -1px;
        color: $font-primary-color;
        max-width: 672px;
        margin-bottom: 1rem;
      }

      .hero-search-title-green {
        color: $dark-green;
      }

      p {
        max-width: 672px;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      .hero-search-form-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-field--style-1 {
          width: 100%;
          padding: 0;
        }

        .hero-search-form-fields-pricing {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          select {
            color: $font-secondary-color;
          }
        }
      }
    }
  }

  .hero-search-buttons {
    .btn.search-btn {
      &:hover {
        background-color: #007175;
      }
    }
  }
  footer {
    padding-bottom: 2rem;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      .__fbcw__bar-widget {
        width: fit-content !important;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .hero-search-container {
    margin-bottom: 0;
    gap: 0;
    .hero-search-wrap {
      border-radius: 0;
      .hero-search {
        padding: 1rem 1rem 0 1rem;

        h1, h2 {
          margin-bottom: .5rem;
        }
      }
      form {
        padding: 1rem 1rem;
        .hero-search-form-fields {
          .hero-search-form-fields-pricing {
            flex-direction: column;
          }
        }
      }
      .hero-search-content {
        .automated-search-form {
          form {
            .automated-search-form-container {
              .form-field-container {
                flex-direction: column;
              }
            }
          }
        }
      }
      footer {
        padding-bottom: 1rem;
      }
    }
  }
}

.hero-banner.dark-text h1, .hero-banner.dark-text p, .hero-banner.dark-text h1, .hero-banner.dark-text p {
  color: #4d5968;
}

/*-------------- Homes Banner: Hero Header -----------------*/
.hero-header {
  background: #232323;
  background-position: center;
  background-size: cover;
  padding: 4em 0;
  position: relative;
}

.hero-header {
  margin: 0;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.hero-banner > * {
  position: relative;
  z-index: 1;
}

.hero-banner p {
  font-size: 18px;
}

.hero-search-wrap {
  .hero-search-content {
    text-align: start;
    .automated-search-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      form {
        .flash-bag {
          max-width: 672px;
          text-align: center;
          .alert {
            padding: 1rem;
          }
        }
        .automated-search-form-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .form-field-container {
            display: flex;
            flex-direction: row;
            gap: 1rem;
          }
          .form-field {
            padding: 0;
            width: 100%;
            select {
              color: $font-secondary-color;
            }
            label {
              display: none;
            }
          }
        }
        p {
          max-width: 672px;
          text-align: center;
          font-size: 0.75rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-banner h2, .main-banner h1 {
    font-size: 60px;
    line-height: 1.2;
  }

  .hero-banner {
    min-height: 580px;
  }
}

@include media-breakpoint-down(md) {
  .hero-search-container {
    .hero-search-wrap {
      .hero-search {
        h1, h2 {
          font-size: 2rem;
          letter-spacing: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-banner {
    min-height: 100vh;
  }
}

.supply_address {
  .hero-search-wrap {
    background-color: $white;
    padding: 2rem;
    border-radius: .5rem;
    border: 1px solid $border-light-grey;
    max-width: 540px;
    .hero-search {
      h1 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -1px;
        color: $font-primary-color;
        max-width: 672px;
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .supply_address {
    .hero-search-wrap {
      padding: 1rem;
      .hero-search {
        h1 {
          font-size: 2.5rem;
        }
      }
    }
  }
}

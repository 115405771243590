.sharing {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;

  &:before,
  &:after {
    display: table;
    content: ' '
  }

  &:after {
    clear: both
  }

  .sharing__list {
    margin: 0;
    padding: 0;

    .sharing__list-item {
      padding-right: 4px;
      padding-bottom: 4px;
      float: left;
      list-style: none;


      .sharing__button {
        text-decoration: none;
        color: #fff;
        border-color: #02405a;
        background-color: #0486be;
        transition: .2s ease-out background-color;
        display: block;
        position: relative;
        width: 50px;
        height: 35px;
        padding-right: 2px;
        padding-left: 2px;
        font-family: Arial, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 37px;

        &:visited {
          color: #fff;
        }

        &:focus,
        &:hover {
          color: #fff;
          background-color: #03638c;
          text-decoration: none;
        }

        &:enabled:active {
          border-top: 1px solid #03638c;
          border-bottom-width: 2px;
        }

        > * {
          border-color: #03638c
        }

        .sharing__icon {
          display: inline-block;
          width: 100%;
          text-align: center;
          overflow: hidden;

          .fa {
            color: inherit;
          }
        }
      }

      // Facebook
      .sharing__button--facebook {
        border-color: #1e2e4f;
        background-color: #3b5998;
        transition: .2s ease-out background-color
      }

      .sharing__button--facebook > * {
        border-color: #2d4373
      }

      .sharing__button--facebook:hover {
        background-color: #2d4373
      }

      .sharing__button--facebook:enabled:active {
        border-top: 1px solid #2d4373;
        border-bottom-width: 2px;
      }

      // Twitter
      .sharing__button--twitter {
        border-color: #006287;
        background-color: #00aced;
        transition: .2s ease-out background-color
      }

      .sharing__button--twitter > * {
        border-color: #0087ba
      }

      .sharing__button--twitter:hover {
        background-color: #0087ba
      }

      .sharing__button--twitter:enabled:active {
        border-top: 1px solid #0087ba;
        border-bottom-width: 2px;
      }

      // LinkedIn
      .sharing__button--linkedin {
        border-color: #0077B5;
        background-color: #0077B5;
        transition: .2s ease-out background-color
      }

      .sharing__button--linkedin > * {
        border-color: #0077B5
      }

      .sharing__button--linkedin:hover {
        background-color: #036ea3
      }

      .sharing__button--linkedin:enabled:active {
        border-top: 1px solid #036ea3;
        border-bottom-width: 2px;
      }

      // Whatsapp
      .sharing__button--whats-app {
        border-color: #16550c;
        background-color: #2daf18;
        transition: .2s ease-out background-color;
        text-align: center
      }

      .sharing__button--whats-app > * {
        border-color: #218212
      }

      .sharing__button--whats-app:hover {
        background-color: #218212
      }

      .sharing__button--whats-app:enabled:active {
        border-top: 1px solid #218212;
        border-bottom-width: 2px;
      }

      .sharing__button--whats-app .sharing__icon {
        position: relative
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .sharing__list-item {
    padding-right: 8px;
    padding-bottom: 8px
  }
}

@media screen and (min-width: 600px) {
  .sharing {
    .sharing__list {
      .sharing__list-item {
        padding-right: 10px;
        padding-bottom: 10px;

        .sharing__button {
          width: 75px;
        }
      }
    }
  }
}

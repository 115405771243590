.breadcrumbs {
  min-height: inherit;
  background: $light-background;
  padding: 0;

  nav#breadcrumbs {
    margin: 1em 0;
    padding: 0;

    ul {
      margin: 0;
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;
      display: inline-block;
      font-size: .75em;

      i {
        margin: 0 3px;
      }

      a {
        color: inherit;
        font-size: 0.85rem;
      }

      &.active a {
        color: #1c1c3c !important;
      }
    }
  }
}

/**
 * Rotate a arrow between up and down. This is used for dropdown menu's.
 *
 * $arrow-color:                color of icon
 * $transition-time-in-seconds: rotation time in seconds
 * $toggle-element:             element that decides which direction the arrow is positioned. The toggle element for bootstrap collapse is [aria-expanded=true]
 * $right:                      right position of arrow
*/

@mixin rotate-arrow(
  $arrow-color: #000000,
  $transition-time-in-seconds: 0.4,
  $right: 13px,
  $toggle-element: '[aria-expanded=true]'
) {
  position: relative;

  &::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f078';
    transition: transform #{$transition-time-in-seconds}s linear;
    font-weight: 900;
    position: absolute;
    right: $right;
    color: $arrow-color;
  }

  &#{$toggle-element}::after {
    transform: rotate(180deg);
  }
}

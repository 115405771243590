// links styling
.terms,
.faqs,
.blog-post.single-post .post-content,
.text-container {
  a:not(.btn):not(.alert):not(.clean):not(.no-link) {
    color: #007bff;
    text-decoration: underline !important;
  }
}

a.no-link,
a.clean {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

a.clean {
  cursor: pointer;
}

.link-primary {
  color: #007bff;
  text-decoration: underline !important;
}

.registration__form__container__paragraph {

  a {
    color: #2D3954;
    text-decoration: underline;
  }
}
// list styling
.text-container {
  ul:not(.sharing__list),
  ol {
    margin: 0.3em 0;

    > li {
      margin: 0.5em 0;
    }
  }
}

// legacy
.list-group-flush {
  .list-group-item {
    border: 0;
    padding: 5px 0;
  }
}

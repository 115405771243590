@import "~bootstrap/scss/mixins";
@import "./assets/huurstunt/scss/utilities/variables";

// styling for form field radio buttons
@mixin form-radio() {
  [type=radio] {
    position: absolute;
    transform: none;
    appearance: auto;
    border-color: transparent;
    background: transparent;
    opacity: 0;
    margin-left: 0;

    &.radio-custom,
    + .radio-custom-label {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    &:focus,
    &:checked {
      border-color: transparent;
      background: transparent;
    }

    & + label {
      position: relative;
      padding-left: 34px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }

    & + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: -2px;
      width: 22px;
      height: 22px;
      border: 1px solid $primary-color;
      border-radius: 100%;
      background: #fff;
    }

    & + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $primary-color;
      position: absolute;
      top: 3px;
      left: 5px;
      border-radius: 100%;
      transition: all 0.2s ease;
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

// styling for form field radio checkboxes
@mixin form-checkbox() {
  [type=checkbox] {
    opacity: 0;
    border-color: transparent;
    background: transparent;

    &.custom-checkbox {
      padding-left: 1.5rem !important;

      .custom-control-label {
        &:before,
        &:after {
          margin-top: -2px;
          width: 22px;
          height: 22px;
        }
      }
    }

    &:focus + .checkbox-custom-label,
    &:focus + .radio-custom-label {
      outline:none;
    }

    &:checked + .checkbox-custom-label:before {
      background: $primary-color;
      border-radius:2px;
      box-shadow: inset 0 0 0 4px #fff;
    }

    &:focus,
    &:checked {
      border-color: transparent;
      background: transparent;
    }

    + .checkbox-custom-label,
    + .radio-custom-label {
      position: relative;
    }

    + .checkbox-custom-label {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      padding-left: 24px;
      font-weight: normal;
    }

    + .checkbox-custom-label:before {
      content: '';
      position: absolute;
      background: #fff;
      border: 1px solid $font-secondary-color;
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin: 0 2px 2px -24px;
      text-align: center;
    }
  }
}

/**
Make checkbox. Include this within a checkbox wrapper/container.
 */
@mixin make-checkbox {
  label {
    position: relative;
    align-self: center;
    left: -0.7rem;

    &::before,
    &::after {
      position: absolute;
      font-weight: 400;
      line-height: 1.5;
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 6px;
      content: "";
      background: 50%/50% 50% no-repeat;
    }

    &::before {
      border: 1px solid #838a94;
    }
  }

  input:checked + label::after {
    background-color: $primary-color;
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+);
  }
}

@mixin show-asterisk($show-asterisk: true) {
  legend,
  label {
    @if $show-asterisk == true {
      &.required:after {
        content: " *";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

/**
  FORM-FIELD
  Represents a single form field
*/

// form field default styling
@mixin form-field--style-1() {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  @include form-radio();
  @include form-checkbox();

  .form-group {
    position: relative;
  }

  legend,
  label {
    color: #2D3954;
  }

  select,
  input:not([type=checkbox]):not([type=radio]),
  button:not(.fileuploader-action) {
    min-height: 55px;
  }

  // form title/label
  .form-field  legend,
  label:not(.form-check-label):not(.checkbox-custom-label):not(.custom-control-label) {
    padding-top: 0.4rem;
  }

  legend,
  label:not(.form-check-label):not(.checkbox-custom-label):not(.custom-control-label) {
    font-weight: 600;
    margin-bottom: 0.6rem;
    padding-bottom: 0;
  }

  .invalid-feedback {
    margin-bottom: 0.5rem;

    .form-error-icon {
      font-size: 0.8rem;
      padding-left: 0;
    }
  }

  // form field
  input:not(.no-shadow):not([type=radio]):not([type=checkbox]),
  select:not(.no-shadow),
  textarea:not(.no-shadow) {
    box-shadow: none;
    box-sizing: border-box;
    font-size: inherit;
    height: 55px;
    border: 1px solid #d9e0e0;
    border-radius: 5px;

    &:focus,
    &:checked {
      box-shadow: none;
      border: 1px solid #008489;
    }

    option {
      border: 0;
    }
  }

  textarea:not(.no-shadow) {
    min-height: 140px;
  }

  .input-group {
    box-shadow: none;
  }

  .form-check,
  .custom-checkbox {
    margin-bottom: 0.3rem;
  }

  .input-group-prepend + input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .input-with-icon {
    position: relative;
    width: 100%;

    i {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      height: 100%;
      left: 15px;
      font-size: 18px;
      color: #a2a9bf;
      font-style: normal;
      cursor: default;
      pointer-events: none;
    }
  }

  &.form-field--stretch .input-with-icon i {
    bottom: 0;
  }
}

// stretch form field vertically
@mixin form-field--stretch {
  justify-content: space-between;

  .form-group {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: space-between;
  }

  fieldset.form-group {
    justify-content: flex-end;
  }
}

// styling for form submit button
@mixin form-field--submit($position: 'space-between') {
  margin-top: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: #{$position};
  align-items: center;

  .required-field-legend {
    font-size: 13px;
    margin-bottom: 10px;
  }

  > p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/**
  FORM-WRAPPER
  Represents a wrapper for multiple form fields
*/

@mixin form-wrapper() {
  @include form-field--style-1();
  @include form-wrapper--margin;

  + .form-wrapper {
    margin-top: -0.5rem;
  }

  .checkbox-custom-label,
  .form-check-label {
    margin-bottom: 7px;
  }
}

// margin between form fields
@mixin form-wrapper--margin($margin: 9) {
  .form-group,
  .form-wrapper__item {
    margin: #{$margin}px 0;
  }
}

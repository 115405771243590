#route-to-terms-view {
  section.terms {
    margin-top: 50px;
  }

  .sharing {
    margin-top: -30px;
  }

  .term-answer {
    @include boxed-widget--padding-xl;

    img {
      max-width: 100%;
    }
  }
}

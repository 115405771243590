#route-to-faq-view {
  section.faqs {
    margin-top: 50px;
  }

  .sharing {
    margin-top: -30px;
  }

  .faq-answer {
    @include boxed-widget--padding-xl;

    img {
      max-width: 100%;
    }
  }
}

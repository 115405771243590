html,
body {
  overflow-x: hidden;
}

body {
  display: block !important;
}

// ios fix
@supports (-webkit-touch-callout: none) {

  // prevents unwanted double tap zoom in ios
  * {
    touch-action: manipulation;
  }
}

#wrapper.add-padding {
  padding-top: 30px;
}

// info alert message
$infoBackground: #d1ecf1;

// danger alert message
$dangerBackground: #f8d7da;

// success alert message
$successBackground: #d4edda;

// warning alert message
$warningBackground: #fff3cd;

a.alert {
  cursor: pointer;
  text-decoration: none;
}

.alert {
  position: relative;

  &.alert--padding-sm {
    margin: 0.4rem 0;
    padding: 0.8rem;

    &.alert--icon {
      background-position: 0.8rem 0.6rem;
      padding-left: 3.6rem;
    }
  }

  &.alert--padding-md {
    margin: 0.6rem 0;
    padding: 1.1rem;

    &.alert--icon {
      background-position: 1.1rem 0.9rem;
      padding-left: 3.6rem;
    }
  }

  &.alert--rounded {
    border-radius: 0.4rem;
  }

  &.alert--pointer-top::after,
  &.alert--pointer-bottom::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    margin: auto;
  }

  &.alert--pointer-top::after {
    top: -1rem;
    transform: rotate(-45deg);
  }

  &.alert--pointer-bottom::after {
    bottom: -1rem;
    transform: rotate(135deg);
  }

  &.alert--icon {
    background-repeat: no-repeat;
    background-size: 30px;
  }

  p {
    margin: 0;
  }

  @mixin alertType($name, $backgroundColor: #ffffff, $iconPath: null) {
    &.alert-#{$name} {
      background-color: $backgroundColor;
      border: 1px solid darken($backgroundColor, 10);

      @if ($iconPath) {
        &.alert--icon {
          background-image: url($iconPath);
        }
      }

      &.alert--shadow {
        box-shadow: rgba(darken($backgroundColor, 50), 0.20) 0 4px 12px;
      }

      &.alert--pointer-bottom::after,
      &.alert--pointer-top::after {
        background: linear-gradient(45deg, rgba(255, 255, 255, 0) 50%, rgba($backgroundColor, 1) 50%);
        border-right: 1px solid darken($backgroundColor, 10);
        border-top: 1px solid darken($backgroundColor, 10);
      }

      h1,
      h2,
      h3,
      p {
        color: darken($backgroundColor, 60);
      }
    }
  }

  @include alertType('info', $infoBackground, '/public/cdn/img/icons/alerts/info.svg');
  @include alertType('danger', $dangerBackground, '/public/cdn/img/icons/alerts/danger.svg');
  @include alertType('success', $successBackground, '/public/cdn/img/icons/alerts/success.svg');
  @include alertType('warning', $warningBackground, '/public/cdn/img/icons/alerts/warning.svg');
  @include alertType('default');
}

.input-with-icon i.fa-search, .input-with-icon i.fa-map-marker-alt {
  z-index: 5;
}

.typeahead {
  &.form-control {
    border: 1px solid #333333;
  }
}

.twitter-typeahead {
  width: 100%;

  .tt-dataset {
    position: relative;
    overflow: hidden;
    border-left: 1px solid #e4e6e6;
    border-right: 1px solid #e4e6e6;
    border-bottom: 1px solid #e4e6e6;
    background-color: white;
  }

  .tt-hint {
    color: #ffffff !important;
  }

  .typeahead {
    background-color: #ffffff;

    &.form-control {
      z-index: 5;
      height: 55px;
      padding-left: 15px;
    }
  }

  .tt-menu {
    padding: 0 !important;
    margin: 0 !important;
    top: 100% !important;
    width: 100% !important;
    border-radius: 5px;
    animation-delay: 1s;
    text-align: left;

    .suggestion-block {
      .btn-secondary {
        color: #ffffff;
      }
    }
  }

  .tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
    border-left: 3px solid transparent;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      cursor: pointer;
    }

    &.tt-cursor,
    &:hover {
      background-color: #ddd;
      border-left: 3px solid #ddd;
    }

    p {
      margin: 0;
    }

  }

  .tt-hint {
    color: #999999
  }

  .suggestion-block {
    padding: 15px 18px;
    font-size: 14px;
    margin-left: -1px;
    width: calc(100% + 2px);
    z-index: 9999;

    .number {
      margin-top: -15px;
      float: right;
      color: grey;
      font-size: small;
    }

    .type {
      color: grey;
      font-size: x-small;
    }


    @media screen and (max-width: 768px) {
      .btn {
        padding: 5px;
        font-size: 13px;
      }
    }

    &.footer {
      border-top: 1px rgba(0, 0, 0, 0.075) solid;
      text-align: center;
    }
  }

  .input-group-append .btn {
    min-width: 110px;
  }
}

.hasTypeahead .input-group-append .btn {
  //margin-left: -2px!important;
}

.twitter-typeahead .form-control:focus,
.twitter-typeahead .form-control:active,
.twitter-typeahead .form-control:hover,
.main-search-input-item.location a {
  z-index: 5;
}

#header {
  .typeahead {
    &.form-control {
      font-size: 16px !important;
      height: 45px !important;
    }
  }

  .header-search {
    .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      &:disabled {
        opacity: 1 !important;
      }
    }
  }
}

@media (min-width: 768px) {
  #header {
    .twitter-typeahead {

      .tt-menu {
        padding: 45px 0 0 0;
        min-width: auto;
      }
    }
  }
  .typeahead {
    &.form-control {
      height: 65px !important;
      font-size: 2em !important;
    }
  }
  .twitter-typeahead {
    .tt-menu {
      width: 100%;
      margin: -50px 0 0 2px;
      padding: 65px 0 0 0;
    }
  }
}

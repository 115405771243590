.image-cover {
  background-size: cover !important;
  background-position: center !important;
}

// make sure the background color for .core-content div element is removed or is set:    background: transparent
// otherwise the parallax effect will not work
img.image-parallax-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.img-wrap {
  height: 310px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  margin: 10px 0;
  border-radius: 4px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background: linear-gradient(to bottom, transparent 25%, #1e2a4c);
    opacity: 0.8;
    z-index: 11;
    transition: 0.3s ease-out;
  }
}

.img-wrap-content {
  position: absolute;
  z-index: 55;
  bottom: 10%;
  margin: 0 auto;
  left: 10%;
  width: 100%;

  h4 {
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    margin: 0;
  }

  span {
    font-size: 16px;
    color: #fff;
  }
}

.img-wrap-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  transition: transform 0.35s ease-out;
}

img.mfp-img,
.disable__select__images {
  user-select: none;
  -webkit-touch-callout: none;
}

.prevent__shifting__images {
  max-width: 100%;
  min-height: 280px;
}

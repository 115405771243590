@import './assets/huurstunt/scss/utilities/common';

// Smooth scroll for back to top only on blog
html {
  scroll-behavior: smooth;
}

body#route-to-news-view .scroll-to-top-button,
body#route-to-blog-view .scroll-to-top-button {
  position: fixed;
  background: $white;
  align-items: center;
  justify-content: center;
  right: 1rem;
  bottom: 3.5rem;
  width: 3rem;
  height: 3rem;
  z-index: 100;
  border-radius: 50%;
  border: 2px solid #d3d7df;

  i {
    font-size: 1.5rem;
    transition: color 0.15s;
  }
}

.scroll-to-top-button {
  display: none;
}

@include media-breakpoint-up(md) {
  body#route-to-news-view .scroll-to-top-button,
  body#route-to-blog-view .scroll-to-top-button {
    bottom: 1rem;
  }
}

//  RIKADA THEME

[data-overlay] {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background: #19365f;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  *:not(.container):not(.bg-img-holder) {
    z-index: 2;
  }

  &.image--light:before {
    background: #fff;
  }

  &.bg--primary:before {
    background: #4a90e2;
  }
}

[data-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before {
  opacity: 0.9;
}

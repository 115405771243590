
/*
Transform a custom icon ($icon-unicode) to a close icon and a close ican back to custom icon.
The toggle between both icons depends if the $toggle-class exists in the html or not.

$icon-unicode:               unicode of fontawesome icons. For example: 'f00d'. Unicode "\f00d" is the close icon from font awesome.
$toggle-class:               the class name in html that decides if the icon is a custom icon or a close icon. If class is present in html, than the close icon is generated.
$transition-time-in-seconds :  animation time between transformation of custom icon and close icon.
 */

@mixin animate-close-icon($icon-unicode: '', $color: #000000, $toggle-class: 'toggle-class', $transition-time-in-seconds: '0.4') {
  $unicode: #{unicode($icon-unicode)};
  $ucWords: #{str-ucwords($toggle-class, true)};
  position: relative;

  &::after {
    font-family: 'Font Awesome 5 Free';
    color: $primary-color;
    font-weight: 900;
    text-align: center;
    font-size: 2rem;
    line-height: 1;
    margin: auto 0;
    content: $unicode;
    transform: scale(1, 1);
  }

  // custom icon
  &:not(.#{$toggle-class}):not(.toggle-init)::after {
    animation: animateToIconUnicode#{$ucWords} #{$transition-time-in-seconds/2}s linear;
  }

  // close icon
  &.#{$toggle-class}::after {
    transform: scale(1, 1);
    animation: animateToClose#{$ucWords} #{$transition-time-in-seconds/2}s linear;
    content: "\f00d";
  }

  @keyframes animateToClose#{$ucWords} {
    0% {
      content: $unicode;
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.1);
    }
    100% {
      content: "\f00d";
      transform: scale(1, 1);
    }
  }

  @keyframes animateToIconUnicode#{$ucWords} {
    0% {
      content: "\f00d";
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.1);
    }
    100% {
      content: $unicode;
      transform: scale(1, 1);
    }
  }
}

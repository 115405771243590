// please do not add more scss to this file. Add new scss in templates or in components folder

[class*=" lni-"], [class^=lni-] {
  font-size: 1.2em;
}

.core-content {
  overflow: hidden;
  background: $light-background;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
  width: calc(50% - 2px);
}

.color-default {
  color: #263238;
}

.fas:not(.registration__form__container) > li,
.far:not(.registration__form__container) > li {
  color: $primary-color;
}

.fa-inline {
  vertical-align: middle;
}

// form
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  border-radius: 50%;
}

// Data of birth
#registration_form_dateOfBirth select.form-control {
  min-width: 70px;
  max-width: 100px;
}

.price-tag {
  position: absolute;
  left: 10px;
  right: initial;
  bottom: 5px;

  display: inline-block;
  background: rgba(0, 0, 0, .5);
  color: #fff;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0;
  padding: .75em 1.5em;
}

// Rental page
section.section-footer {
  background: #fcfcfc;
  overflow-x: hidden;
  border-top: 1px solid #f0f0f0;
  padding: 50px 0;
  box-shadow: none;
}

.property3-slide .no-image {
  max-width: 500px;
}

/* for Firefox we split up class/selection it doesn't work */
.text-blurred {
  color: transparent !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.text-blurred::selection {
  color: transparent !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.text-blurred::-moz-selection {
  color: transparent !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Like Icon */
span.like-icon {
  color: #fff;
  font-size: 19px;
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid $white;
  transition: all 0.4s;
}

.like-icon:before,
.like-icon:after {
  transition: opacity 0.3s, transform 0.4s cubic-bezier(.0, -.41, .19, 2.5);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 4px;
}

.like-icon:before {
  content: "\f004";
  transform: scale(0.95);
}

.like-icon:after {
  content: "\f004";
  opacity: 0;
  transform: scale(0.5);
}

.like-icon.liked:after {
  opacity: 1;
  transform: scale(0.95);
}

.like-icon:not(.liked) {
  background-color: rgba(24, 24, 24, .4);
}

.like-icon:hover,
.like-icon.liked {
  background-color: #ff093d;
  color: #fff;
}

.like-icon.liked:before {
  opacity: 0;
}

.like-icon:hover, .like-icon.liked {
  color: #fff;
}

.like-button .like-icon,
.like-button .like-icon:hover, .like-button .like-icon.liked {
  color: #fff;
}

// Search below menu
.search {
  background: $primary-color;
  padding: 10px 0;
}

// 404 rental page
.typeahead {
  .form-inline {
    .form-group {
      .btn {
        min-height: 60px;
        margin-left: -10px;
        border-radius: 0 10px 10px 0;
        z-index: 999;
      }
    }
  }
}

// account/profile
// File upload
.fileuploader ~ .custom-file-label {
  display: none;
}

.dashboard-navbar {
  h4 {
    font-size: 25px;
  }
}

.dashboard-wraper {
  .block-header {
    border-bottom: 2px solid #f7f9fb;
    padding-bottom: 10px;
    margin-bottom: 35px;
    font-weight: 600;
  }

  table.property-table-wrap {
    border-bottom: 1px solid #e4e4e4;
  }
}

// blog
.dashboard-list-box h4.gray {
  background-color: #fff
}
.box-shadow {
  box-shadow: none;
}

// searchpage rentals
.property-listing.property-1 .description {
  padding: 0 15px;
}

// Media queries
@include media-breakpoint-down(xs) {
  .mobile-pull {
    margin-left: -15px !important;
    margin-right: -15px !important;
    overflow-x: hidden;
  }

  .block-wrap,
  .mobile-full-width {
    margin-left: -15px !important;
    margin-right: -15px !important;
    border-radius: 0 !important;
    width: calc(100% + 30px) !important;
  }

  .mobile-white-background {
    background-color: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-light-grey;
  }

  .mobile-boxed-widget--transparent {
    background-color: transparent;
    box-shadow: none;
    padding: 1em;

    .modal-divider span {
      background-color: $light-background;
    }
  }

  .modal-body {
    padding: 3em 1em;
  }
  // rental list
  .list-layout .property-listing.property-1 .listing-img-wrapper {
    min-height: 370px;
  }

  // rental overview
  #route-to-search-slug-params .breadcrumbs,
  #route-to-search-all .page-title,
  #route-to-search-slug .page-title,
  #route-to-search-slug-params .page-title {
    display: none;
  }

  // rental view
  .property_details .breadcrumbs,
  .property_details .page-title {
    display: none;
  }

  // rental list (neighborhood) full width and height
  .property-listing.property-1 .listing-img-wrapper img,
  .property-listing.property-2 .listing-img-wrapper,
  .property-listing.property-2 .listing-img-wrapper .list-img-slide,
  .property-listing.property-2 .listing-img-wrapper img {
    max-height: initial;
  }

  // rental contact
  .rental-banner {
    text-align: center;
    padding: 1em;
    margin: 0;
  }

  body.fixed-element {
    margin-bottom: 75px;

    &.fixed-element-small {
      margin-bottom: 45px;
    }
  }
}

// Remove max height of images to show full height so we can make images more wide.
.property-listing.property-2 .listing-img-wrapper,
.property-listing.property-2 .listing-img-wrapper .list-img-slide,
.property-listing.property-2 .listing-img-wrapper .list-img-slide img {
  max-height: initial;
}

.property-listing.property-2 .listing-img-wrapper .list-img-slide {
  min-height: 320px;
}

.slick-list .property-listing.property-2 .listing-img-wrapper .list-img-slide img.no-image {
  min-height: 360px;
}

.dashboard-wraper .property-listing.property-2 .listing-img-wrapper .list-img-slide img.no-image {
  min-height: initial;
}

.property-small-view .property-listing.property-2 .listing-img-wrapper .list-img-slide img.no-image {
  min-height: initial;
}

.listing-badge.fulfilled-badge {
  margin: 0;
  z-index: 999;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  position: absolute;
  float: left;
  line-height: 28px;
  display: block;
  padding: 10px;
  font-size: 1.5em;
  width: 360px;
  left: -50px;
  top: 60px;
  transform: rotate(-35deg);
  background: $primary-color;
}



.dashboard-wraper .block-header {
  font-weight: 500;
}

.single-advance-property .slider-for img {
  width: initial;
  min-height: inherit;
  max-height: 500px;
  margin: 0 auto;
}

// page title
.page-title {
  padding: 1.5em 0;
  height: inherit;

  h1,
  h2 {
    margin-top: 1em;
    margin-bottom: 0;
  }

  + * {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .page-title {
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  #route-to-home .hero-banner {
    background-color: #fff;
    padding-top: 0;
  }
  #route-to-home .hero-banner::after {
    background: none;
  }

  .property-listing.property-1 .description {
    margin-bottom: 30px;
  }
}

/* typo suggestion spacing */
.suggestion-message {
  padding-top: 1em;
}

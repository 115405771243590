.modal-body,
.checkout-registration__form__content,
.registration__form__container {
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: $white;
  border: solid 1px $border-light-grey;
  border-radius: 0.5rem;

  &__label label {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    color: $font-primary-color;
    margin-bottom: 0.25rem;
  }

  &__firstname {
    margin-right: 0.5rem;
    width: 100%;
  }

  &__lastname {
    margin-left: 0.5rem;
    width: 100%;
  }

  &__password {
    margin-bottom: 0.5rem;
  }

  &__heading {
    margin-bottom: 0.25rem;
    font-size: 1.2rem;
    line-height: 1.4;
  }

  &__lead {
    margin-bottom: 1.5rem;
    line-height: 1.5;
    color: $font-secondary-color;
    font-weight: normal;
  }

  &__sublead {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    color: $font-secondary-color;
    font-weight: normal;
  }

  .form-control {
    border: 0.063rem solid $border-light-grey;
    height: 3.125rem;
    text-indent: 1.875rem;
    font-size: 0.875rem;
    line-height: 1.6;
    color: $font-secondary-color;
    margin-bottom: 0.75rem;
  }

  textarea.form-control {
    min-height: 8.75rem;
    padding: 1rem;
    text-indent: initial;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid $theme-color;
  }

  .form-group {
    display: initial;
    width: 100%;

      label {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: .25rem;
        line-height: 1.4;
      }

      button {
        margin-bottom: 0;
      }
  }

  &__icon__container {
    position: relative;
  }

  &__icon {
    color: $light-grey;
    font-size: 1.125rem;
    position: absolute;
    top: 2.563rem;
    left: 0.875rem;
  }

  &__icon__email,
  &__icon__password {
    color: $light-grey;
    font-size: 1.125rem;
    position: absolute;
    top: 1rem;
    left: 0.875rem;
  }

  .btn-theme {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  [type=checkbox] + .checkbox-custom-label {
    font-size: 0.875rem;
    line-height: 1.4;
    color: $font-secondary-color;
    padding-left: 1rem;
    margin-bottom: 0;
  }

  &__terms {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.2;

    a {
      text-decoration: underline;
      color: $font-primary-color;
    }
  }

  &__divider {
    margin-top: 2.188rem;

    &:before {
      background: $form-input-divider;
      height: 0.063rem;
    }

    &__divider__paragraph {
      position: relative;
      background: $white;
      padding: 0 0.938rem;
      margin: 0 0 1rem;
    }
  }

  .facebook-connect,
  .google-connect {
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1.125rem;
    border-radius: 0.25rem;
    border: 1px solid $form-social-border;
    background-color: $white;
    width: 100%;
    font-size: 0.75rem;
    line-height: 2;
    margin-bottom: 0.5rem;
    color: $font-secondary-color;
    transition: border 0.3s;

    &:hover {
      border-color: black;
    }
  }

  .facebook-connect i {
    color: $facebook-color;
  }

  .facebook-connect i:before {
    padding-right: 0.5rem;
  }

  .google-connect {
    margin-bottom: 0;
  }
}

.modal-body,
.checkout-registration__form__content {
  padding: initial;
  margin-bottom: initial;
  border: initial;
  border-radius: initial;

  .form-field.form-field--style-1 input:not(.no-shadow):not([type=radio]):not([type=checkbox]) {
    min-height: 50px;
    height: 50px;
  }
}

.login-form {

  .login-fields {
      margin-top: 34px;

    a {
      display: inline-block;
      margin-bottom: 1rem;
    }

    .form-field.form-field--stretch.form-field--style-1.form-field--no-asterisk.col {
      padding-top: 0;
      padding-bottom: 0;

        label {
          padding: 0;
          font-size: 1rem;
          line-height: 1.4;
          margin-bottom: 0.25rem;
        }

        input {
          font-size: 0.875rem;
          line-height: 1.6;
          margin-bottom: 0.75rem;
          border-color: $border-light-grey;
        }

        input:focus {
          box-shadow: none;
          border: 1px solid #008489;
        }
    }
  }

  .login-buttons {

    .form-field.form-field--submit-center {
      display: initial;

      .btn {
        margin: 0;
      }
    }
  }

  form .form-field.form-field--style-1 .input-with-icon i {
    top: -6px;
  }
}

.modal-body {

  .login-form .login-fields .form-field.form-field--stretch.form-field--style-1.form-field--no-asterisk.col .input-with-icon i {
      top: -6px;
  }

  .login-form {

    form .form-field.form-field--style-1 .input-with-icon i, .fake-form-field.form-field--style-1 .input-with-icon i {
      top: 5px;
    }
  }
}

.modal-body,
.checkout-registration__form__content {

  .registration__form__container__heading,
  .registration__form__container__lead {
    display: none;
  }

  .registration {
    padding-top: 34px;
  }

  .registration__form__container__terms a {
    color: $form-links;
  }
}

.modal-dialog {

  .modal-body {

    .fake-form-field.form-field--style-1 .input-with-icon i, form .form-field.form-field--style-1 .input-with-icon i {
      top: -6px;
    }
  }
}

.registered__form__container {

  .form-control,
  textarea.form-control {
    margin-bottom: 1.5rem;
  }
}

.registration {

  .registration__form__container__lead {
    margin-bottom: 1rem;
  }

  .registration__form__container__label {

    .control-label {
      margin-bottom: 0.5rem;
    }
  }

  .registration__form__container__icon__container {

    .form-control {
      margin-bottom: 0.75rem;
    }
  }

  .form-field.form-field--style-1 {
    padding: 0;
    margin-bottom: 1.5rem;
  }

  button.btn.btn-lg.btn-theme.registration__form__container__button {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(md) {

  .registration__form__container {
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    &__subheading {
      margin-top: 0.5rem;
    }

    &__firstname,
    &__lastname {
      margin: 0;
    }

    .btn-theme {
      width: 100%;
    }
  }

  .registered__form__container {

    .form-control {
      margin-bottom: 0.5rem;
    }

    textarea.form-control {
      margin-bottom: 1rem;
    }
  }

  .registration {

    .registration__form__container__icon__container {

      .form-control {
        margin-bottom: 0.5rem;
      }
    }

    .form-field.form-field--style-1 {
      padding: 0;
      margin: 0.5rem 0 1rem;
    }
  }
}


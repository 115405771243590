footer.skin-dark-footer {
  z-index: 1;

  p,
  .footer-widget ul li a,
  .about-us a {
    color: #d5d8e0;
  }

  &.bg-primary {
    background-color: #2d4e6a;
  }

  .above-footer {
    padding: 50px 0;
    background: #efefef;
    box-shadow: none;

    h4.widget-title {
      color: #2D3954;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 17px;
    }

    .row > div {
      margin-bottom: 15px;
    }

    .footer-widget {
      padding: 0;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-top: 15px;
          display: block;

          &:first-child {
            margin-top: 0;
          }

          a {
            color: #252c41;
          }
        }
      }
    }
  }

  .below-footer {

    p.widget-title {
      color: $white;
      font-size: 1.2em;
      font-weight: 600;
      text-transform: none;
      line-height: 1.2;
      margin-bottom: .5rem;
      margin-top: 0;
    }

    .footer-widget {
      padding: 70px 0;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          display: block;

          a {
            color: $white;
            display: block;
            padding: 6px 0;
          }
        }
      }

      .about-us a {
        text-decoration: underline;
      }
    }

    ul.footer-bottom-social {
      margin-bottom: 1.5rem;
      display: block;

      li {
        margin-right: 5px;
        display: inline-block;
      }

      i {
        font-size: 1.7em;
      }
    }
  }

  .footer-bottom {
    background: #1b2132;
    padding: 20px 0;
  }

  ul.footer__quality-marks {
    display: flex;
    align-items: center;

    > li:not(:last-child) {
      margin-right: 13px;
    }
  }
}

@include media-breakpoint-down(sm) {
  footer.skin-dark-footer {
    .below-footer {
      padding-top: 12px;

      .footer-widget {
        padding: 20px 0;

        ul li a {
          padding: 9px 0;
        }
      }
    }
  }
}

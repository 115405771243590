/**
 * Show logo
 *
 * $show: if true, then $white logo is generated. If false, then colored logo is generated.
 */
@mixin showWhiteLogo($show) {
  a.main-menu__logo {
    &.white {
      display: if($show, flex, none);
    }
    &.color {
      display: if($show, none, flex);
    }
  }
}

/**
 * Make menu transparent in top scroll position
 */
@mixin transparent {

  // Disable transparency when scroll position is below 50 pixels from window top
  &.scroll-position-below-transparency {
    background: $white;
    @include showWhiteLogo(false);
  }

  // Enable transparency when scroll position is between 0 to 50 pixels from window top
  &:not(.scroll-position-below-transparency) {
    background: transparent;
    border-color: transparent;
    @include showWhiteLogo(true);

    p {
      font-weight: 600;
    }

    p,
    i {
      color: $white;
    }

    .divider-right::after,
    .divider-left::before {
      background: $white;
    }

    .main-menu__mobile::after,
    .main-menu__search__button::after {
      color: $white;
    }

    .main-menu__search .main-menu__search__box {
      border-color: $white;

      ::placeholder {
        color: $white;
      }

      input {
        color: $white;
      }
    }

    ul.main-menu__options {
      li.divider-right::after,
      li.divider-left::before {
        background: $white;
      }

      li p,
      li a {
        color: $white;
      }

      li.button-border a {
        border-color: $white;
      }

      li.main-menu__options__account::after {
        color: $white;
      }
    }
  }
}

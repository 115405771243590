#newsletter-modal,
#newsletter-form-modal {
  p.cancel-subscription {
    font-size: 13px;
  }
}

.newsletter-modal__email-disclaimer {
  font-size: 11px;
  margin: 5px 0 0;

  a {
    text-decoration: underline;
  }
}

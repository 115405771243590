// property type
.property-type {
  position: absolute;
  top: .5rem;
  right: .5rem;
  padding: .5rem 1rem;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 2;
  color: $white;
  border: 3px solid rgba(white, 0.7);

  &.property--green {
    background: $property-active;
  }

  &.property--red {
    background: $property-rented-out;
  }

  &.property--orange {
    background: $property-under-option;
    color: $font-primary-color;
  }
}

// property favorite
.listing-like-top {
  position: absolute;
  z-index: 2;
  left: 1rem;
  top: 1rem;

  .like-icon::after,
  .like-icon::before {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2px;
    bottom: 0;
  }
}

.xs-fixed-bottom,
.text-center {

  .sharing {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    &::before {
      display: none;
    }

    p {
      margin: 0;
    }

    .sharing__list {
      display: flex;
      gap: .5rem;

      .sharing__list-item {
        padding: 0;
        width: 100%;

        .sharing__list-item-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.188rem;
          height: 2.188rem;
          border-radius: 100%;
          border: 0.063rem solid $border-light-grey;
          transition: all .2s ease-in-out;

          &:hover {
            border: 0.063rem solid $primary-color;
          }

          i {
            margin: 0;
            color: $font-primary-color;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {

  .term-share,
  .post-content { 

    .xs-fixed-bottom {
      background-color: $white;
      border-top: 0.063rem solid $share-border-mobile;
      bottom: 0;
      z-index: 99;
      left: 0;
      right: 0;
      position: fixed;
      text-align: center;
      box-shadow: 0 0 0.625rem 0.125rem $share-shadow-mobile;

      &.xs-fixed-full-width {
        padding: 1rem 1.5rem;
      }

      .sharing {
        margin: 0 !important;
        flex-wrap: initial;
        gap: 1rem;

        p {
          margin: 0;
        }

        .sharing__list {
          padding: 0;
          gap: 1rem;
          margin-bottom: -0.125rem;
          display: flex;
          

          .sharing__list-item {
            padding: 0;
            display: initial;
            width: initial;
            float: none;

            .sharing__button {          
              height: 2.813rem;
              line-height: 2.938rem;
            }
          }
        }
      }
    }
  }
}

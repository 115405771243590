// Rikada styling overwrites
.hide {
  display: none;
}

.bg-theme {
  background-color: $theme-color;
}

a.link:hover, a.link:focus, a:hover, a:focus,
.cn-info-icon i,
.dw-proprty-info li {
  color: $primary-color;
}

.h-100 {
  min-height: initial;
}

@include media-breakpoint-down(sm) {

  // menu
  .header-light nav.headnavbar {
    .nav-header {
      background-color: #fff;
    }

    .nav-container ul:not(.dropdown-menu) > li > a {
      color: $primary-color;
    }

    .logo {
      display: block !important;
    }

    .white {
      display: none !important;
    }
  }

  // modal
  h4.modal-header-title {
    font-size: 2em;
  }

  footer > div:not(#above-footer) .footer-widget ul.footer-menu li {
    margin-bottom: -10px;
  }

  h4.widget-title {
    margin-bottom: 10px;
  }
}

@include media-breakpoint-down(md) {
  .core-content {
    .header-transparent nav.headnavbar {
      &.core-nav .wrap-core-nav-list {
        transition: opacity 0s;
      }

      .nav-container .toggle-bar,
      .toggle-bar.core-nav-toggle .fas {
        color: $primary-color;
      }
    }

    .header-fixed nav.headnavbar {
      &.core-nav .wrap-core-nav-list {
        transition: all .5s ease-in-out;
      }
    }

    nav {
      .nav-header {
        min-height: 75px;
      }

      form {
        max-width: 80vw;
        padding: 5px 0 0 70px;
      }

      .brand {
        position: absolute;
        top: 5px;
        left: 45px;

        img.logo-small {
          max-width: 50px;
        }
      }
    }
  }
}

// On favorites fix padding.
.col-md-6 > .single-items,
.col-md-4 > .single-items {
  padding: 0;
}

// modal
h4.modal-header-title {
  font-size: 3em;
  line-height: 1.2em;
  margin-top: 0;
}

#newsletter-modal h4.modal-header-title {
  font-size: 2.5em;
}

// utilities
@import './utilities/common';

// vendor
@import './vendor/bootstrap';
@import './vendor/fontawesome';
//@import '~select2/dist/css/select2.css';
//@import './vendor/slick';
@import './vendor/rikada-plus-overwrites';
//@import './vendor/jquery-ui';

// styling for huurstunt and kamerstunt
@import 'assets/common/scss/templates/checkout/partials/progressbar';
@import '../../common/scss/templates/checkout/partials/registration';
@import 'assets/common/scss/components/collapse';
@import 'assets/common/scss/components/clean';
@import 'assets/common/scss/components/divider';
@import 'assets/common/scss/components/social-login';
@import 'assets/common/scss/components/social-share';

// components
@import './components/utilities';
@import './components/alert';
@import './components/scroll-to-top-button';
@import './components/buttons';
@import './components/section';
@import './components/images';
@import './components/data-overlay';
@import './components/typeahead';
@import './components/hero';
@import './components/list';
@import './components/links';
@import './components/typography';
@import './components/property';
@import './components/table';
@import './components/blockquote';
@import './components/form';
@import './components/boxed-widget';

// custom. Please do not add more scss to this file. Add new scss in templates or components folder
@import './deprecated/custom';

// template includes
@import './templates/includes/base/partials/main-menu-notifications';
@import './templates/includes/base/partials/trustpilot';
@import './templates/includes/base/partials/main-menu-slide';
@import './templates/includes/base/partials/main-menu-top';
@import './templates/includes/base/partials/footer';
@import './templates/includes/base/partials/modal';
@import './templates/includes/partials/newsletter/newsletter';
@import './templates/includes/base/partials/searchbar';
@import './templates/includes/base/partials/flashbag';
@import './templates/includes/base/partials/breadcrumbs';
@import './templates/includes/base/default';

// template admin-bundle
@import './templates/admin-bundle/faq/view';
@import './templates/admin-bundle/terms/view';

.searchbar-mobile {
  background: #dae3ea;
  padding: 7px;

  .input-with-icon {
    position: relative;
    display: flex;
    align-items: center;

    input {
      text-indent: 19px;
    }

    i {
      position: absolute;
      margin-left: 10px;
    }
  }
}

.searchbar-desktop {
  background: #dae3ea;
  border-bottom: 1px solid #cddbe7;
  padding: 7px 0;

  .form-group.row {
    margin: 0;
    position: relative;
    flex-flow: row nowrap;
    align-items: stretch;
    height: 55px;

    > * {
      margin-right: 2px;
    }

    input {
      box-shadow: none;
      padding-left: 40px;
      height:  55px;
      flex: 1;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    .twitter-typeahead {
      display: block !important;
    }

    select {
      background: $white;
      border-radius: 0 !important;
      border: 1px solid #ced4da;
      width: 112px;
      text-align-last: center;
      text-align: center;
      color: #495057;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 5px;
      padding-right: 2rem;
      cursor: pointer;
    }

    .icons {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      pointer-events: none;

      i {
        position: absolute;

        &.fa-angle-down {
          right: 115px;
        }

        &.fa-search {
          left: 15px;
        }
      }
    }

    button[type=submit] {
      display: block;
      margin: 0;
      padding: 0 20px;
      background: $primary-color;
      color: $white;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      border: 1px solid #dae3ea;
    }
  }
}

@include media-breakpoint-down(md) {
  .titlebar .row {
    justify-content: center;

    #mobile-search {
      margin-right: 0;
      width: 95vw;
    }
  }
}

.social-login {

  > button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 7px;
    border: 1px solid $form-social-border;
    background: transparent;
    transition: border 0.3s;

    &:hover {
      border-color: black;
    }

    &:focus {
      outline: 0;
    }
  }

  // facebook/google
  .facebook-connect .facebook-connect__wrapper,
  .google-connect .google-connect__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .google-official-btn__item {
      display: flex;
    }

    .facebook-connect__text,
    .google-connect__text {
      font-size: 0.75rem;
      line-height: 2;
    }
  }
}

.google-official-btn__item {
  padding-right: 0.5rem;
}

.fa-facebook-f:before {
  padding-right: 0;
}

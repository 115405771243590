@import './includes/share';

// used for media-breakpoint-down
@import '~bootstrap/scss/functions';

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/spacing';
// end use

.btn.btn-xlg {
  padding: 20px 75px;
}

.grecaptcha-badge {
  display: none !important;
}

// boxed widget
.boxed-widget-white {
  background-color: #fff;
  border-radius: 10px;
  padding: 2.5em 2em;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}

.box-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

// can be used for chaining data with vue.
.is-updating {
  opacity: .5;
  transition: opacity .5s;
}

@include media-breakpoint-down(md) {
  // Add this class to a element to make it fixed to the bottom.
  .xs-fixed-bottom {
    background-color: #ffffff;
    border-top: 1px solid #cccccc;
    padding: 1rem;
    bottom: 0;
    z-index: 99;
    left: 0;
    right: 0;
    position: fixed;
    text-align: center;
    box-shadow: 0 0 10px 2px #f1f1f1;

    &.xs-fixed-full-width {
      padding: 0;
    }

    .sharing {
      margin: 0 !important;

      .sharing__list {
        padding: 0;
        margin-bottom: -2px;
        display: table;
        width: 100%;

        .sharing__list-item {
          padding: 0;
          display: table-cell;
          float: none;

          .sharing__button {
            width: 100%;
            height: 45px;
            line-height: 47px;
          }
        }
      }
    }
  }
}

input.possible-typo {
  border: 1px solid #dc3435 !important;
}

.suggestion-message a {
  cursor: pointer;
  text-align: left;
}

// Creates a horizontal stroke at both sides of the base element

.divider-fade-out {
  position: relative;
  margin: 1.5rem 0 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 0.875rem;

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    right: 0;
    height: 1px;
    background: #E1E5F2;
  }

  span,
  p {
    position: relative;
    background: #fff;
    padding: 0 15px;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }
}

.modal-divider {
  position: relative;
  margin: 20px 0;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 1px solid #E1E5F2;
  }

  span {
    position: relative;
    background: #fff;
    padding: 0 20px;
  }
}

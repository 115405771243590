.text-container {
  table {
    width: 100%;
    margin: 2.5rem 0;
    border-style: hidden;
    display: block;
    padding-bottom: 0.5rem;
    overflow-x: auto;

    td {
      padding: 5px 8px;
      border: 1px solid #dbe5e8 !important;
    }

    th {
      border: 1px solid #dbe5e8 !important;
    }

    tr:nth-child(2n) {
      background: $row-light-grey;
    }
  }

  @include media-breakpoint-up(md) {
    table {
      display: table;
    }
  }
}

#dashboard {
  table {
    width: 100%;
    margin: 2.5rem 0;
    border-style: hidden;

    tr:nth-of-type(2n+1) {
      background: #f1f3f8;
    }

    tr:nth-child(2n) {
      background: transparent;
    }
  }
}

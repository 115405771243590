@import '~@fortawesome/fontawesome-free/scss/fontawesome';
.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}
.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@import "~bootstrap/scss/mixins";

// Vertical spacing between the boxed widgets
$verticalSpacing: 30px;

// boxed widgets
@mixin boxed-widget--clean($darken: 0) {
  box-shadow: none !important;
  position: relative;
  border: 1px solid darken($border-light-grey, $darken);
  border-radius: 7px;
  background-color: $white;
}

@mixin boxed-widget--transition {
  transition: border 0.2s linear;

  @include media-breakpoint-up(sm) {
    transition: border 0.1s linear;

    img {
      transform: scale(1);
      transition: all 0.2s linear;
    }

    &:not(.rental-watermark-disable-hover):hover
    &:not(.rental-greenlist-disable-hover):hover {
      img {
        transform: scale(1) !important;
        filter: brightness(0.90);
      }
    }
  }
}

@mixin boxed-widget--mobile($stretchDistance: 15, $type: "px") {

  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    margin-left: -#{$stretchDistance}#{$type} !important;
    margin-right: -#{$stretchDistance}#{$type} !important;
    width: calc(100% + #{2 * $stretchDistance}#{$type}) !important;
  }
}

@mixin boxed-widget--mobile-compact {
  @include boxed-widget--mobile;
  @include media-breakpoint-down(xs) {
    padding: 1em;
  }
}

/*
 * Stretch box-widgets to equal height.
 * Only works when included after <div class="col-XX-XX"> html tag and when box-widgets are columns, not rows.
 */
@mixin boxed-widget--stretch {
  height: calc(100% - #{$verticalSpacing})
}

@mixin boxed-widget($padding: 2.5, $type: "rem") {
  padding: #{$padding}#{$type};
  margin-bottom: $verticalSpacing;
  @include boxed-widget--clean;
}

@mixin boxed-widget--padding-xl {
  padding: 5rem 11vw;
  margin-bottom: $verticalSpacing;

  @include media-breakpoint-down(xs) {
    padding: 3rem 2rem;
  }

  @include boxed-widget--clean;
}
